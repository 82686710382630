import { ColumnComponent } from "../components/ColumnComponent";
import { useSearch } from "../components/layout";
import columns from "../model/Visma.BusinessModel.Columns.json";
import tables from "../model/Visma.BusinessModel.Tables.json";
import { TableFilter, ColumnFilter } from "../utils/filters";
import { relations } from "../utils/models";
import { SetTextToClipboard, ToPascalCase } from "./SetTextToClipboard";
import { setDefaultResultOrder } from "dns";
import markdownToTxt from "markdown-to-txt";
import { ChatCompletionRequestMessage, Configuration, OpenAIApi } from "openai";
import { useMemo, useState } from "react";
import { useRef, useEffect } from "react";
import { AiOutlineCopy as CopyIcon } from "react-icons/ai";
import {
  BiCog as SettingsIcon,
  BiPlay as PlayIcon,
  BiTrash as TrashIcon,
} from "react-icons/bi";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import { useLocalStorage } from "usehooks-ts";
import { Column } from "~/model/Visma.BusinessModel";

function GroupByTableNo(columns: Column[]) {
  const groups = columns.reduce((groups, item) => {
    const val = item.TableNo;
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {} as { [key: string]: Column[] });

  return Object.keys(groups).map((key) => ({
    TableNo: key,
    columns: groups[key],
  }));
}

function ChatComponent({
  msg,
}: {
  msg: ChatCompletionRequestMessage;
}): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ref]);

  return (
    <div className="" ref={ref}>
      {msg.role === "assistant" ? (
        <>
          <div className="relative">
            <ReactMarkdown
              rehypePlugins={[rehypeHighlight]}
              className="text-shark-700 p-4 [&>p]:py-2"
            >
              {msg.content}
            </ReactMarkdown>
            <button
              className="bg-shark-700 text-shark-50 p-2 rounded-md bottom-2 right-2 opacity-50 hover:opacity-100 hover:scale-125 transition-all absolute"
              onClick={() =>
                SetTextToClipboard(
                  msg.content
                    ?.split("\n")
                    ?.filter((x) => x.trim().length > 0 && !x.startsWith("```"))
                    .join("\n")
                )
              }
            >
              <CopyIcon />
            </button>
          </div>
        </>
      ) : (
        <div className="bg-shark-700 text-shark-50 p-4 whitespace-pre overflow-auto">
          {msg.content}
        </div>
      )}
    </div>
  );
}

export default function Index() {
  const search = useSearch();
  const [openaiApiKey, setOpenaiApiKey] = useLocalStorage<string | null>(
    "openai-api-key",
    null
  );

  const [openaiModel, setOpenaiModel] = useLocalStorage<string | null>(
    "openai-model",
    "gpt-3.5-turbo"
  );

  const [chatLog, setChatLog] = useLocalStorage<ChatCompletionRequestMessage[]>(
    "ai-chat-log",
    []
  );

  const [additionalInfo, setAdditionalInfo] = useState<string | null>(null);
  const [showSettings, setShowSettings] = useState(!openaiApiKey);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const configuration = new Configuration({
    apiKey: openaiApiKey || "",
  });
  const openai = new OpenAIApi(configuration);

  const filteredTables = useMemo(
    () => tables.filter((item) => TableFilter(item, search)),
    [search]
  );

  const currentColumns = useMemo(
    () =>
      columns
        // check if the column is part of the filtered tables
        .filter(
          (item) =>
            search?.indexOf(".") === -1 ||
            filteredTables.find((x) => x.TableNo === item.TableNo)
        )
        .filter((item) => ColumnFilter(item, search)),
    [search, filteredTables]
  );

  const cols = useMemo(() => GroupByTableNo(currentColumns), [currentColumns]);

  let prompt = useMemo(
    () => `
  Using the following GraphQL schema where SQL names and relations are in the comments:

  Fields wrapped with ItemsNode all have a node with items as the first decendant. The field names are correct. It is important to use the correct field names in the query.

  Do not include joins if they are aldready covered by the existing relations.

  Stop when all fields from the SQL table are included.

  External parameters in the SQL query often start with a @ sign and should be replaced with function parameters.

  If there is a filter on a join, you might have to split the query and fetch the joined table separately. Values from a query can be exported using @export(as: "name") and then used as $name in the next part of the query. If @export is used, then the name of the exported value must be part of the query definition with a default value.

  Subfields are always prefixed with joinup_ or joindown_. The table name after _ is always in PascalCase. You cannot add filters to subfields starting with joinup_, and if that is part of the provied query it should be removed.

  OrderBy is called "sortOrder" and is an object on the form { [fieldName]: ASC|DESC }. The field name is the same as the field name in the query.

  Always use joinup and joindown to fetch related data. Relations might be defined using _via_ in the name. If that is the case, then the name of the relation is the name of the table joined to, and the name of the field is the name of the field in the joined table.

  Give the query a name that describes what it does. The name should be in PascalCase.

  Aliasing for fields is done like { Alias: field }. If a field has an alias in the SQL query that differs from the GraphQL field name you should use it as an alias. Do not use aliases if the alias and the field name are the same.

  interface ItemsNode<T> {
    items: [T];
  }

  type Query {
    useCompany(no: Int!){ ## no is the company number commonly called $companyNo.
      ${filteredTables
        .map(
          (x) =>
            `${ToPascalCase(x.Identifier)}: ItemsNode<${
              x.Identifier
            }> ## start with an items node as the first decendant. filters go here as filter: { field: { _eq: 'val' } }. Valid filters methods are: _eq, _gt, _gte, _is_not_null, _is_null, _is_off, _is_on, _like, _lt, _lte_, _not_eq, _not_like. sortOrder: { field: ASC|DESC }. The field name is the same as the field name in the query.`
        )
        .join("\n")}
    }
  }
  ${cols
    .map((group) => {
      const table = filteredTables.find((x) => x.TableNo === group.TableNo);
      const relatedTables = relations
        .filter((x) => x.FromTableNo === group.TableNo)
        .map((x) => {
          const relation = tables.find((y) => y.TableNo === x.ToTableNo);
          const fromColumn = columns.find(
            (c) => c.TableNo === x.FromTableNo && c.Identifier === x.Identifier
          );
          return {
            GraphQLFieldName: x?.AltBigIdentifier ?? x?.Identifier,
            GraphQLTableName:
              relation?.AltBigIdentifier ?? relation?.Identifier,
            SqlTableName: relation?.SqlName,
            SqlFieldName: fromColumn?.SqlName,
            JoinName:
              relation?.Identifier === x.Identifier
                ? `joinup_${x.Identifier}`
                : `joinup_${relation?.Identifier}_via_${
                    x?.AltBigIdentifier ?? x?.Identifier
                  }`,
          };
        })
        .filter(
          (x) =>
            x.GraphQLTableName &&
            currentColumns.find((y) => y.Identifier === x.GraphQLFieldName)
        );
      const reverseRelatedTables = relations
        .filter((x) => x.ToTableNo === group.TableNo)
        .map((x) => {
          const relation = tables.find((y) => y.TableNo === x.FromTableNo);
          const fromColumn = columns.find((c) => c.Identifier === x.Identifier);
          return {
            GraphQLFieldName: x.Identifier,
            GraphQLTableName: relation?.Identifier,
            SqlTableName: relation?.SqlName,
            SqlFieldName: fromColumn?.SqlName,
            JoinName:
              relation?.Identifier === x.Identifier
                ? `joindown_${relation?.Identifier}`
                : `joindown_${relation?.Identifier}_via_${
                    x?.AltBigIdentifier ?? x?.Identifier
                  }`,
          };
        })
        .filter(
          (x) =>
            x.GraphQLTableName &&
            currentColumns.find((y) => y.Identifier === x.GraphQLFieldName)
        );
      return `type ${table?.Identifier} { ## ${table?.SqlName}
        ${group.columns
          .map((x) => `${ToPascalCase(x.Identifier)}: String ## ${x.SqlName}`)
          .join("\n")
          .trim()}
        ${relatedTables
          .map(
            (x) =>
              `${x.JoinName}: ${x.GraphQLTableName} ## ${x.SqlTableName} via ${table?.SqlName}.${x.SqlFieldName}`
          )
          .join("\n")
          .trim()}
        ${reverseRelatedTables
          .map(
            (x) =>
              `${x.JoinName}: ItemsNode<${x.GraphQLTableName}> ## ${x.SqlTableName} via ${x.SqlTableName}.${x.SqlFieldName}. Decendants must be wrapped with an items node. Keep the _ in the field name.`
          )
          .join("\n")
          .trim()}
      }`;
    })
    .join("\n")}

  SAMPLE GRAPHQL QUERY:

  query SampleQuery($cid: Int!) {
    useCompany(no: $cid) {
      someTable { ## use the name of this table as the name of the query (replace SampleQuery). You can add filters here. You cannot filter on values from the query. Ignore invalid filters.
        items {
          someField
          joinup_SomeTable { ## filters are not available for joinups. The via is skipped for joinups where the table name is the same as the field name.
            someOtherTableField
          }
          joinup_SomeTable_via_SomeOtherField { ## filters are not available for joinups.
            someOtherTableField
          }
          joindown_SomeTable { ## filters are available for joindown. The via is skipped for joinups where the table name is the same as the field name.
            items {
              someOtherTableField
            }
          }
          joindown_SomeTable_via_SomeOtherField { ## filters are available for joindown
            items {
              someOtherTableField
            }
          }
        }
      }
    }
  }

  SQL QUERY:
  ${search}
  
  Remember to reply with just the GraphQL query and no other prose or text.`,
    [cols, currentColumns, filteredTables, search]
  );

  async function sendPromptToOpenAi() {
    if (!openaiApiKey) {
      alert("Please enter an OpenAI API key.");
      return;
    }
    setError("");
    setShowSettings(false);

    try {
      const message: ChatCompletionRequestMessage =
        additionalInfo && chatLog.length > 0
          ? {
              role: "user",
              content: additionalInfo,
            }
          : {
              role: "user",
              content: prompt,
            };
      if (!additionalInfo) {
        setChatLog([]);
      }
      setIsLoading(true);
      const completion = await openai.createChatCompletion({
        model: openaiModel ?? "",
        max_tokens: 2048 * 2,
        messages: [
          {
            role: "system",
            content:
              "You are an expert on SQL and GraphQL, and you are converting SQL queries into GraphQL queries for the Visma Business NXT GraphQL API. The user will provide you with the GraphQL schema, where the correspondant SQL tables and relations are in the comments. You will write the GraphQL query. Reply with markdown. Remember to annotate queries with ```graphql. Reply with just the GraphQL query and no other prose or text.",
          },
          ...chatLog
            ?.filter((x) => x.role && x.content && additionalInfo)
            .map((x) => ({
              role: x.role,
              content: x.content,
            })),
          message,
        ],
      });
      const completion_text = completion.data?.choices?.[0];
      setChatLog((old) => [
        ...old,
        message,
        {
          role: "assistant",
          content: completion_text.message?.content ?? "",
        } as ChatCompletionRequestMessage,
      ]);
      setAdditionalInfo("");
      setIsLoading(false);
    } catch (e: any) {
      setError(e.response.data.error.message ?? e.message);
      setIsLoading(false);
    }
  }

  return (
    <div className="">
      <div className="mb-4 bg-shark-200">
        <h3 className="text-xl py-3 px-3 bg-shark-700 flex">
          <span className="flex-1">AI GraphQL Query Assistant</span>
          {!isLoading && openaiApiKey && search && cols?.length > 0 && (
            <button
              onClick={sendPromptToOpenAi}
              title="Get GraphQL Query Suggestion"
              className="px-2"
            >
              <PlayIcon />
            </button>
          )}
          {chatLog.length > 0 && (
            <button onClick={() => setChatLog([])} title="Clear chat log">
              <TrashIcon />
            </button>
          )}
          <button
            onClick={() => setShowSettings((old) => !old)}
            className="px-2"
            title="Show settings"
          >
            <SettingsIcon />
          </button>
        </h3>
        {showSettings && (
          <div className="p-3 text-shark-700">
            <label htmlFor="openai_token" className="block">
              OpenAI API Key
            </label>
            <input
              type="text"
              name="openai_token"
              id="openai_token"
              className="w-full p-2 text-shark-700"
              placeholder="OpenAI API Key"
              value={openaiApiKey || ""}
              onChange={(e) => setOpenaiApiKey(e.target.value)}
            />
            <label htmlFor="openai_model" className="block mt-2">
              OpenAI Model
            </label>
            <select
              name="openai_model"
              id="openai_model"
              className="w-full p-2"
              value={openaiModel ?? undefined}
              onChange={(e) => setOpenaiModel(e.target.value)}
            >
              <option value="gpt-4">gpt-4</option>
              <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
            </select>
            <p className="text-sm text-shark-700 pt-1">
              For best results, use gpt-4. gpt-3.5-turbo is faster, but less
              accurate and supports fewer tokens.
            </p>
          </div>
        )}

        <div className="">
          {chatLog &&
            chatLog
              .filter((x, i) => i > 0)
              .map((msg, i) => (
                <ChatComponent key={i} msg={msg}></ChatComponent>
              ))}
          {isLoading && (
            <>
              {additionalInfo && (
                <ChatComponent
                  msg={{
                    role: "user",
                    content: additionalInfo,
                  }}
                />
              )}
              <div className="p-4 text-shark-700 ">
                <span className="animate-pulse">...</span>
              </div>
            </>
          )}
        </div>
        {error && (
          <div className="bg-red-900 text-red-50 p-4 overflow-auto">
            {error}
            <button
              onClick={() => setError("")}
              className="w-full block mt-3 bg-red-700"
            >
              Close
            </button>
          </div>
        )}
        {openaiApiKey && search && cols?.length > 0 && (
          <>
            {chatLog.length > 1 && (
              <textarea
                placeholder="Need more help? Give additional context or paste your errors here and try again."
                className="w-full h-20 p-4 bg-shark-700 text-shark-50"
                value={!isLoading ? additionalInfo || "" : ""}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                onKeyDown={(e) => {
                  if (isLoading) {
                    e.preventDefault();
                    return;
                  }
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    sendPromptToOpenAi();
                  }
                }}
              ></textarea>
            )}
            {!isLoading && (
              <button
                onClick={sendPromptToOpenAi}
                className="w-full p-2 bg-shark-400 text-md text-center"
              >
                Get GraphQL Query suggestion
              </button>
            )}
          </>
        )}
      </div>
      {false &&
        cols &&
        cols.map((t) => {
          const table = tables.find((item) => item.TableNo === t.TableNo);
          return (
            <div key={t.TableNo} className="mb-4 bg-shark-200">
              <h3
                className="text-xl py-3 px-3 bg-shark-700"
                id={table?.Identifier}
              >
                {table?.Identifier}
                <CopyIcon
                  className="inline-block ml-2 cursor-pointer"
                  title="Copy column names to clipboard"
                  onClick={() =>
                    SetTextToClipboard(
                      t.columns
                        .filter((x) => !x.ReadAccess)
                        .map(
                          (x) => `${ToPascalCase(x.Identifier)} # ${x.SqlName}`
                        )
                        .join("\n")
                    )
                  }
                />
              </h3>
              {t.columns.map((column) => (
                <ColumnComponent
                  key={column.ColumnNo}
                  column={column}
                  table={table}
                />
              ))}
            </div>
          );
        })}
    </div>
  );
}
