import ReactGA from "react-ga4";
import toast from "react-hot-toast";

export function ToPascalCase(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export function SetTextToClipboard(text: string) {
  navigator.clipboard.writeText(text);
  const lines = text.split("\n").length;
  let toastText = `Copied '${text.split("\n")[0]}'`;
  if (lines > 1) {
    toastText += ` and ${lines - 1} more lines to clipboard`;
  }
  toast(toastText);
  ReactGA.event({
    category: "clipboard",
    action: "copy",
    label: text,
    value: lines,
    nonInteraction: false, // optional, true/false
    transport: "beacon", // optional, beacon/xhr/image
  });
}
