import { useSearch } from "../components/layout";
import { TableFilter } from "../utils/filters";
import { tables } from "../utils/models";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export default function Index() {
  const search = useSearch();

  const filteredTables = useMemo(
    () =>
      tables
        .filter((item) => TableFilter(item, search))
        .sort((a, b) => (a.TableNo > b.TableNo ? 1 : -1)),
    [search]
  );
  const classes =
    "p-3 mb-1 border-b-2 border-shark-400 items-center justify-center";
  return (
    <div className="bg-shark-600">
      <h2 className="text-xl py-3 px-3 bg-shark-700">Tables</h2>
      <div className={classes}>
        <Link to={"/"} className="block">
          Show all
        </Link>
      </div>
      {filteredTables &&
        filteredTables.map((item) => {
          const url = search ? `#${item.Identifier}` : item.Identifier;
          return (
            <div key={item.TableNo} className={classes}>
              {search ? (
                <a href={url} className="block">
                  {item.Identifier}
                  <span className="text-sm ml-2 inline-block">
                    {item.SqlName} ({item.TableNo})
                  </span>
                </a>
              ) : (
                <Link to={url} className="block">
                  {item.Identifier}
                  <span className="text-sm ml-2 inline-block">
                    {item.SqlName} ({item.TableNo})
                  </span>
                </Link>
              )}
            </div>
          );
        })}
    </div>
  );
}
