import Layout from "./components/layout";
import "./index.css";
import Details from "./pages/details";
import Search from "./pages/search";
import SearchAi from "./pages/search-gpt3";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

ReactGA.initialize("G-W222Z45JEG");

const router = createBrowserRouter([
  {
    path: "/",
    id: "root",
    element: <Layout />,
    shouldRevalidate: () => false,
    errorElement: <div className="">error</div>,

    children: [
      {
        index: true,
        id: "search",
        element: <Search />,
        errorElement: <div>Error loading search</div>,
      },
      {
        index: true,
        id: "ai",
        path: "ai",
        element: <SearchAi />,
        errorElement: <div>Error loading search</div>,
      },
      {
        path: ":tableName",
        errorElement: <div>Error loading details for table</div>,
        id: "details",
        element: <Details />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")!).render(
  <>
    <RouterProvider router={router} />
  </>
);
