import { Column, Table } from "~/model/Visma.BusinessModel";

const tableCache = new Map<string, string[]>();

export function TableFilter(item: Table, str: string | null) {
  if (!str) {
    return true;
  }

  let strs: string[] = [];
  if (tableCache.has(str)) {
    strs = tableCache.get(str) || [];
  } else {
    strs = Array.from(
      new Set(
        str
          .toLocaleLowerCase()
          .replace(/\n/gi, " ")
          .trim()
          .replace(/[^\w\s.]/gi, "")
          .split(" ")
          .filter((x) => !!x)
      )
    );
  }
  if (strs.length > 1 || str.indexOf(".") > -1) {
    return strs.some((s) => {
      if (s.indexOf(".") > -1) {
        s = s.split(".")[0];
      }
      return (
        s && //item.Identifier.toLocaleLowerCase() === s ||
        Match(item.SqlName, s)
      );
    });
  }

  return strs.some((s) => {
    s = s.split(".")[0];
    return s && (Match(item.Identifier, s) || Match(item.SqlName, s));
  });
}

function MatchSQL(item: Column, str: string) {
  return Match(item?.SqlName, str);
}

function MatchIdentifier(item: Column, str: string) {
  return Match(item?.Identifier, str);
}

function Match(itemName: String | undefined, str: string) {
  if (!str || str === "*") return true;
  str = str.trim();
  if (str.startsWith("*") && str.endsWith("*")) {
    str = str.replace(/[*]/gi, "");
    return str && itemName?.toLocaleLowerCase().includes(str);
  } else if (str.endsWith("*")) {
    str = str.substring(0, str.length - 1);
    return str && itemName?.toLocaleLowerCase().startsWith(str);
  } else if (str.startsWith("*")) {
    str = str.substring(1);
    return str && itemName?.toLocaleLowerCase().endsWith(str);
  } else {
    return str && itemName?.toLocaleLowerCase() === str;
  }
}

const columnCache = new Map<string, string[]>();

export function ColumnFilter(item: Column, str: string | null) {
  if (!str) {
    return true;
  }

  let strs: string[] = [];
  if (columnCache.has(str)) {
    strs = columnCache.get(str) || [];
  } else {
    strs = Array.from(
      new Set(
        str
          .toLocaleLowerCase()
          .replace(/\n/gi, " ")
          .replace(/[^\w\s.*]/gi, " ")
          .split(" ")
          .map((x) => x.trim())
          .filter((x) => !!x)
      )
    );
    columnCache.set(str, strs);
  }

  return strs.some((s) => {
    if (s.indexOf(".") > -1) {
      const split = s.split(".");
      if (split.length > 1) {
        const column = split[1];
        return column && MatchSQL(item, column);
      }
      return false;
    }
    return s && MatchSQL(item, s);
  });
}
