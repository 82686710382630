import { SetTextToClipboard, ToPascalCase } from "../pages/SetTextToClipboard";
import { AiOutlineCopy as CopyIcon } from "react-icons/ai";
import { Column } from "~/model/Visma.BusinessModel";

export function CopyOptions({ columns }: { columns: Column[] }) {
  return (
    <>
      <CopyIcon
        className="inline-block ml-2 cursor-pointer"
        title="Copy column names to clipboard. Hold shift or ctrl to copy with sql name as alias."
        onClick={(e) =>
          SetTextToClipboard(
            columns
              .filter((x) => !x.ReadAccess)
              .map((x) =>
                e.shiftKey || e.ctrlKey
                  ? `${x.SqlName}: ${ToPascalCase(x.Identifier)}`
                  : `${ToPascalCase(x.Identifier)} # ${x.SqlName}`
              )
              .join("\n")
          )
        }
      />
    </>
  );
}
