const textMap = new Map<number, string>([
  [279, "AccountingTransactionControlStatus"],
  [171, "AccountSet"],
  [308, "AlternativeProductGrouping1"],
  [309, "AlternativeProductGrouping2"],
  [326, "AppointmentDescription"],
  [240, "AppointmentGrouping1"],
  [249, "AppointmentGrouping10"],
  [250, "AppointmentGrouping11"],
  [251, "AppointmentGrouping12"],
  [241, "AppointmentGrouping2"],
  [242, "AppointmentGrouping3"],
  [243, "AppointmentGrouping4"],
  [244, "AppointmentGrouping5"],
  [245, "AppointmentGrouping6"],
  [246, "AppointmentGrouping7"],
  [247, "AppointmentGrouping8"],
  [248, "AppointmentGrouping9"],
  [140, "AppointmentPriority"],
  [192, "AssociateGrouping1"],
  [201, "AssociateGrouping10"],
  [202, "AssociateGrouping11"],
  [203, "AssociateGrouping12"],
  [193, "AssociateGrouping2"],
  [194, "AssociateGrouping3"],
  [195, "AssociateGrouping4"],
  [196, "AssociateGrouping5"],
  [197, "AssociateGrouping6"],
  [198, "AssociateGrouping7"],
  [199, "AssociateGrouping8"],
  [200, "AssociateGrouping9"],
  [159, "AssociateInformationGroup1"],
  [160, "AssociateInformationGroup2"],
  [161, "AssociateInformationGroup3"],
  [162, "AssociateInformationGroup4"],
  [163, "AssociateInformationGroup5"],
  [164, "AssociateInformationGroup6"],
  [165, "AssociateInformationGroup7"],
  [166, "AssociateInformationGroup8"],
  [325, "BankFormat"],
  [187, "BudgetLineType"],
  [216, "CapitalAssetGrouping1"],
  [225, "CapitalAssetGrouping10"],
  [226, "CapitalAssetGrouping11"],
  [227, "CapitalAssetGrouping12"],
  [217, "CapitalAssetGrouping2"],
  [218, "CapitalAssetGrouping3"],
  [219, "CapitalAssetGrouping4"],
  [220, "CapitalAssetGrouping5"],
  [221, "CapitalAssetGrouping6"],
  [222, "CapitalAssetGrouping7"],
  [223, "CapitalAssetGrouping8"],
  [224, "CapitalAssetGrouping9"],
  [330, "CommentCodeForPaymentAgreements"],
  [144, "CreateDocument"],
  [143, "CrmTexts"],
  [150, "CustomerPriceGroup1"],
  [151, "CustomerPriceGroup2"],
  [152, "CustomerPriceGroup3"],
  [141, "DayPriority"],
  [331, "DeliveryAlternativeGrouping1"],
  [332, "DeliveryAlternativeGrouping2"],
  [135, "DeliveryMethod"],
  [139, "DeliveryPriority"],
  [134, "DeliveryTerms"],
  [145, "District"],
  [147, "DocumentGroup"],
  [131, "DocumentName"],
  [182, "EftCurrencyCode"],
  [184, "EftDeclarationCode"],
  [186, "EftFormType"],
  [185, "EftPaymentMethod"],
  [183, "EftTaxCode"],
  [855, "EmailTemplateGroup"],
  [167, "EmployeePriceGroup"],
  [191, "EuGoodsStatisticsNo"],
  [967, "ExemptReason"],
  [281, "ExternalConfigurationGrouping1"],
  [282, "ExternalConfigurationGrouping2"],
  [283, "ExternalConfigurationGrouping3"],
  [284, "ExternalConfigurationGrouping4"],
  [322, "ExternalExportGrouping"],
  [289, "FreeInformation1Type2"],
  [290, "FreeInformation1Type3"],
  [291, "FreeInformation2Type2"],
  [292, "FreeInformation2Type3"],
  [293, "FreeInformation3Type2"],
  [294, "FreeInformation3Type3"],
  [327, "FreeInformationCategory"],
  [295, "FreeInformationGrouping1"],
  [304, "FreeInformationGrouping10"],
  [305, "FreeInformationGrouping11"],
  [306, "FreeInformationGrouping12"],
  [296, "FreeInformationGrouping2"],
  [297, "FreeInformationGrouping3"],
  [298, "FreeInformationGrouping4"],
  [299, "FreeInformationGrouping5"],
  [300, "FreeInformationGrouping6"],
  [301, "FreeInformationGrouping7"],
  [302, "FreeInformationGrouping8"],
  [303, "FreeInformationGrouping9"],
  [288, "FreeInformationType1"],
  [129, "FreeText"],
  [204, "GeneralLedgerAccountGrouping1"],
  [213, "GeneralLedgerAccountGrouping10"],
  [214, "GeneralLedgerAccountGrouping11"],
  [215, "GeneralLedgerAccountGrouping12"],
  [205, "GeneralLedgerAccountGrouping2"],
  [206, "GeneralLedgerAccountGrouping3"],
  [207, "GeneralLedgerAccountGrouping4"],
  [208, "GeneralLedgerAccountGrouping5"],
  [209, "GeneralLedgerAccountGrouping6"],
  [210, "GeneralLedgerAccountGrouping7"],
  [211, "GeneralLedgerAccountGrouping8"],
  [212, "GeneralLedgerAccountGrouping9"],
  [836, "GiroType"],
  [142, "InformationCategory"],
  [285, "InterestRateGroup"],
  [1016, "InvoiceNote"],
  [280, "MessageType"],
  [266, "OrderGrouping1"],
  [275, "OrderGrouping10"],
  [276, "OrderGrouping11"],
  [277, "OrderGrouping12"],
  [267, "OrderGrouping2"],
  [268, "OrderGrouping3"],
  [269, "OrderGrouping4"],
  [270, "OrderGrouping5"],
  [271, "OrderGrouping6"],
  [272, "OrderGrouping7"],
  [273, "OrderGrouping8"],
  [274, "OrderGrouping9"],
  [149, "OrderPriceGroup"],
  [228, "OrgUnitGrouping1"],
  [237, "OrgUnitGrouping10"],
  [238, "OrgUnitGrouping11"],
  [239, "OrgUnitGrouping12"],
  [229, "OrgUnitGrouping2"],
  [230, "OrgUnitGrouping3"],
  [231, "OrgUnitGrouping4"],
  [232, "OrgUnitGrouping5"],
  [233, "OrgUnitGrouping6"],
  [234, "OrgUnitGrouping7"],
  [235, "OrgUnitGrouping8"],
  [236, "OrgUnitGrouping9"],
  [189, "OrgUnitStatus"],
  [851, "OssTaxTerm"],
  [137, "PaymentMethod"],
  [138, "PaymentPriority"],
  [136, "PaymentTerms"],
  [168, "PayrollRateNo"],
  [264, "PriceRefundGrouping1"],
  [265, "PriceRefundGrouping2"],
  [190, "PriceType"],
  [153, "ProductCategory"],
  [252, "ProductGrouping1"],
  [261, "ProductGrouping10"],
  [262, "ProductGrouping11"],
  [263, "ProductGrouping12"],
  [253, "ProductGrouping2"],
  [254, "ProductGrouping3"],
  [0xff, "ProductGrouping4"],
  [0x100, "ProductGrouping5"],
  [257, "ProductGrouping6"],
  [258, "ProductGrouping7"],
  [259, "ProductGrouping8"],
  [260, "ProductGrouping9"],
  [154, "ProductPriceGroup1"],
  [155, "ProductPriceGroup2"],
  [156, "ProductPriceGroup3"],
  [278, "ProductTransactionControlStatus"],
  [172, "ProductType1"],
  [173, "ProductType2"],
  [174, "ProductType3"],
  [175, "ProductType4"],
  [157, "ProxyType"],
  [329, "RegistrationTypeForPaymentAgreements"],
  [130, "ReminderText"],
  [328, "RemittanceCodeForRemittanceAgreements"],
  [132, "ReportHeading"],
  [158, "RoleType"],
  [286, "ShipmentGrouping1"],
  [287, "ShipmentGrouping2"],
  [148, "SmsProvider"],
  [188, "StockCountGroup"],
  [310, "StructureGrouping1"],
  [319, "StructureGrouping10"],
  [320, "StructureGrouping11"],
  [321, "StructureGrouping12"],
  [311, "StructureGrouping2"],
  [312, "StructureGrouping3"],
  [313, "StructureGrouping4"],
  [314, "StructureGrouping5"],
  [315, "StructureGrouping6"],
  [316, "StructureGrouping7"],
  [317, "StructureGrouping8"],
  [318, "StructureGrouping9"],
  [133, "SumLine"],
  [170, "TaxAndAccountingGroup"],
  [324, "TaxTerm"],
  [323, "TimeScheduleBalanceGroup"],
  [146, "Trade"],
  [176, "TransactionGroup1"],
  [177, "TransactionGroup2"],
  [178, "TransactionGroup3"],
  [179, "TransactionGroup4"],
  [169, "Unit"],
  [180, "VoucherGroup1"],
  [181, "VoucherGroup2"],
  [307, "VoucherTypeText"],
]);
export default textMap;
