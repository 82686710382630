import { ColumnComponent } from "../components/ColumnComponent";
import { CopyOptions } from "../components/CopyOptions";
import { useSearch } from "../components/layout";
import { ColumnFilter } from "../utils/filters";
import { tables, columns, relations } from "../utils/models";
import { Link, useParams } from "react-router-dom";

export default function Index() {
  const params = useParams();
  const search = useSearch();
  const table = tables.find(
    (item) =>
      item.Identifier.toLocaleLowerCase() === params.tableName?.toLowerCase() ||
      item.SqlName?.toLocaleLowerCase() === params.tableName?.toLowerCase()
  );

  if (!table) {
    return <div>Tabellen finnes ikke</div>;
  }
  const tableColumns = columns
    .filter((item) => item.TableNo === table.TableNo)
    .filter((item) =>
      search && search.indexOf(".") > -1 ? ColumnFilter(item, search) : true
    );
  const tableRelations = relations.filter(
    (item) => item.FromTableNo === table.TableNo
  );
  const reverseRelations = relations.filter(
    (item) => item.ToTableNo === table.TableNo
  );

  return (
    <div className="">
      <h2 className="text-xl py-3 px-3 bg-shark-700">
        {table.Identifier} <CopyOptions columns={tableColumns} />
      </h2>
      <p className="text-xs pb-2 px-3 bg-shark-700">
        {table.SqlName} ({table.TableNo})
      </p>
      <div className=" bg-shark-200">
        {tableColumns.map((item) => (
          <ColumnComponent key={item.ColumnNo} column={item} table={table} />
        ))}
      </div>
      {tableRelations.length > 0 && (
        <>
          <h3 className="text-xl py-3 px-3 bg-shark-700">Relations</h3>
          <div className="bg-shark-300">
            {tableRelations.map((item) => {
              const target = tables.find((x) => x.TableNo === item.ToTableNo);
              const fromColumns = item.FromColumns.split(",").map(
                (x) => columns.find((y) => y.ColumnNo === x)?.SqlName
              );
              const toColumns = item.ToColumns.split(",").map(
                (x) => columns.find((y) => y.ColumnNo === x)?.SqlName
              );
              return (
                <div
                  key={item.RelationNo}
                  className="p-3 mb-1 border-b-2 border-shark-900 text-shark-900 items-center justify-center"
                >
                  <Link to={"/" + target?.Identifier}>
                    <span title={fromColumns.join(",")}>
                      [{item.RelationNo}] {item.Identifier}
                    </span>{" "}
                    {`=>`}{" "}
                    <span title={toColumns.join(",")}>
                      {target?.Identifier}
                    </span>
                    <span className="text-sm ml-2 inline-block">
                      {target?.SqlName} ({item.ToTableNo})
                    </span>
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      )}
      {reverseRelations.length > 0 && (
        <>
          <h3 className="text-xl py-3 px-3 bg-shark-700">Reverse Relations</h3>
          <div className="bg-shark-500">
            {reverseRelations.map((item) => {
              const target = tables.find((x) => x.TableNo === item.FromTableNo);
              return (
                <div
                  key={item.RelationNo}
                  className="p-3 mb-1 border-b-2 border-shark-900 text-shark-50 items-center justify-center"
                >
                  <Link to={"/" + target?.Identifier}>
                    {target?.Identifier}.{item.Identifier}
                    <span className="text-sm ml-2 inline-block">
                      {target?.SqlName} ({item.FromTableNo})
                    </span>
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
