import { ColumnComponent } from "../components/ColumnComponent";
import { CopyOptions } from "../components/CopyOptions";
import { useSearch } from "../components/layout";
import columns from "../model/Visma.BusinessModel.Columns.json";
import tables from "../model/Visma.BusinessModel.Tables.json";
import { TableFilter, ColumnFilter } from "../utils/filters";
import { useEffect, useMemo } from "react";
import ReactGA from "react-ga4";
import { Column } from "~/model/Visma.BusinessModel";

function GroupByTableNo(columns: Column[]) {
  const groups = columns.reduce((groups, item) => {
    const val = item.TableNo;
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {} as { [key: string]: Column[] });

  return Object.keys(groups).map((key) => ({
    TableNo: key,
    columns: groups[key],
  }));
}

export default function Index() {
  let search = useSearch();

  search = search?.replace(/--.*/g, "") ?? null;
  search = search?.replace(/\/\*[\s\S]*?\*\//g, "") ?? null;

  const filteredTables = useMemo(
    () => tables.filter((item) => TableFilter(item, search)),
    [search]
  );

  const currentColumns = useMemo(
    () =>
      columns
        // check if the column is part of the filtered  tables
        .filter(
          (item) =>
            filteredTables.length > 0 &&
            filteredTables.find((x) => x.TableNo === item.TableNo)
        )
        .filter((item) => ColumnFilter(item, search)),
    [search, filteredTables]
  );

  const cols = useMemo(() => GroupByTableNo(currentColumns), [currentColumns]);

  return (
    <div className="">
      {cols &&
        cols.map((t) => {
          const table = filteredTables.find(
            (item) => item.TableNo === t.TableNo
          );
          return (
            <div key={t.TableNo} className="mb-4 bg-shark-200">
              <h3
                className="text-xl py-3 px-3 bg-shark-700"
                id={table?.Identifier}
              >
                {table?.Identifier}
                <CopyOptions columns={t.columns} />
              </h3>
              <div>
                {t.columns.map((column) => (
                  <ColumnComponent
                    key={column.ColumnNo}
                    column={column}
                    table={table}
                  />
                ))}
              </div>
            </div>
          );
        })}
    </div>
  );
}
