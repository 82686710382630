import Tables from "./tables";
import { debounce } from "lodash";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";
import { Toaster } from "react-hot-toast";
import { AiOutlineArrowDown as ArrowDown } from "react-icons/ai";
import { Link, Outlet } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

const searchContext = createContext<string | null>(null);

export const useSearch = () => {
  const context = useContext(searchContext);
  return context;
};

export default function Index() {
  const [search, setSearch] = useLocalStorage<string | null>("search", null);

  const throttledSetSearch = useMemo(
    () => debounce(setSearch, 500),
    [setSearch]
  );

  useEffect(() => {
    if (search) {
      ReactGA.event({
        category: "search",
        action: "search",
        label: search,
        nonInteraction: false, // optional, true/false
        transport: "beacon", // optional, beacon/xhr/image
      });
    }
  }, [search]);

  return (
    <searchContext.Provider value={search}>
      <div className="px-2 text-purple-500 font-mono">
        <header className="m-4 max-w-5xl mx-auto">
          <div className="flex justify-between items-center">
            <h1 className=" font-bold">
              <span className="text-4xl">VBNXT</span>
              <span className="text-2xl">.rocks</span>
              <span className="text-sm ml-2 inline-block">model browser</span>
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <p className="py-4 text-sm">
              This is a browser tool for the Visma Business Model. You can paste
              a SQL query from Visma Business and it will show you the tables
              and columns used in the query.
              <span className="block pt-4">
                <Link to="/ai">
                  <span className="text-md px-4 py-2 text-shark-500 bg-shark-200 underline inline-block">
                    Try the AI GraphQL Assistant
                  </span>
                </Link>
              </span>
            </p>
            <p className="py-4 text-sm text-shark-300">
              Tip: Use the copy button to copy the GraphQL query to your
              clipboard. Hold shift or ctrl to alias all columns to the SQL
              name.
              <span className="block pt-4">
                Fields with enum or flag values are marked with a flag icon.
                Click the icon to see the values. For flag values you can select
                the values to calculate the flag value.
              </span>
            </p>
          </div>
        </header>
        <div className="mb-2 max-w-5xl mx-auto ">
          <textarea
            className="border-0 outline-none appearance-none rounded-none border-b-2 border-b-shark-400 p-2 w-full bg-shark-700 font-mono"
            placeholder="Search for tables or columns or paste a SQL query"
            rows={(search?.split("\n")?.length || 1) + 1}
            defaultValue={search || ""}
            onChange={(e) => throttledSetSearch(e.target.value)}
          />
        </div>
        <div className="text-center">
          <a
            href="#content"
            className="md:hidden bg-shark-200 text-shark-600 rounded mb-4 p-2 inline-block justify-items-center"
          >
            <ArrowDown className="inline-block" size={20} />
            Skip to content
          </a>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 max-w-5xl mx-auto mb-3">
          <div className="">
            <Tables />
          </div>
          <div id="content">
            <Outlet />
          </div>
        </div>
      </div>
      <Toaster />
    </searchContext.Provider>
  );
}
