import FlagTypes from "../model/FlagTypes.json";
import ValueTypes from "../model/ValueTypes.json";
import Domains from "../model/Visma.BusinessModel.Domains.json";
import { SetTextToClipboard, ToPascalCase } from "../pages/SetTextToClipboard";
import textTypes from "../utils/textTypes";
import clsx from "clsx";
import { useState } from "react";
import toast from "react-hot-toast";
import { HiBan, HiFlag, HiViewList } from "react-icons/hi";
import { ImEyeBlocked } from "react-icons/im";
import { Column, Table } from "~/model/Visma.BusinessModel";

export function ColumnComponent({
  column,
  table,
  className,
}: {
  column: Column;
  table?: Table;
  className?: string;
}) {
  const textType = Domains.find(
    (d) => d.DomainNo === column.DomainNo
  )?.TextTypeNo;
  const [currentFlagValue, setCurrentFlagValue] = useState(0);
  const [showValueDetails, setShowValueDetails] = useState(false);
  const textTypeName = textTypes.get(parseInt(column.DomainNo));
  const flagType =
    column.AltBigIdentifier in FlagTypes
      ? FlagTypes[column.AltBigIdentifier as keyof typeof FlagTypes]
      : null;
  const valueType =
    column.AltBigIdentifier in ValueTypes
      ? ValueTypes[column.AltBigIdentifier as keyof typeof ValueTypes]
      : null;
  const valueDetails = flagType ?? valueType;
  return (
    <div
      className="p-3 mb-1 border-b-2 border-shark-900 text-shark-900"
      style={{ minHeight: "50px", contentVisibility: "auto" }}
    >
      <div
        className={clsx(
          "items-center flex",
          className,
          column.ReadAccess || column.WriteAccess ? "opacity-60" : ""
        )}
        title={
          !!column.ReadAccess || !!column.WriteAccess
            ? "System column. Not accessible."
            : undefined
        }
      >
        <span>{column.Identifier}</span>
        <span className="text-sm ml-2 inline-block">
          <span
            className="cursor-copy"
            onClick={() =>
              SetTextToClipboard(
                ToPascalCase(column.Identifier) +
                  " # " +
                  column.SqlName +
                  (textType ? ", TxtTp: " + textType : "")
              )
            }
          >
            {column.SqlName && `${table?.SqlName}.${column.SqlName}`} (
            {column.ColumnNo}){" "}
          </span>
          {textType && (
            <abbr
              title={textTypeName}
              className="cursor-copy"
              onClick={() => SetTextToClipboard(textType.toString())}
            >
              TxtTp {textType}
            </abbr>
          )}
        </span>

        <span className="justify-end flex-1 text-right">
          {valueDetails && (
            <button
              onClick={() => setShowValueDetails(!showValueDetails)}
              type="button"
              className="mx-1 p-0 text-sm"
              title="Show field value details"
            >
              {valueType ? (
                <HiViewList className="inline-block" />
              ) : (
                <HiFlag className="inline-block" />
              )}
            </button>
          )}
          {column.ReadAccess && (
            <ImEyeBlocked
              className="inline-block mx-1 text-red-800"
              title="System field. Not retrieveable."
            />
          )}
          {column.WriteAccess && (
            <HiBan
              className="inline-block mx-1 text-red-800"
              title="System field. Not writeable."
            />
          )}
        </span>
      </div>
      {showValueDetails && valueDetails && (
        <table className="table text-sm m-2">
          <tbody>
            {Object.entries(valueDetails).map((entry) => (
              <tr key={entry[0]}>
                <th className="pr-2 text-right">{entry[0]}</th>
                {flagType && (
                  <td>
                    <input
                      type="checkbox"
                      id={`flag_${entry[0]}`}
                      checked={
                        (currentFlagValue & parseInt(entry[0])) ===
                        parseInt(entry[0])
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCurrentFlagValue(
                            currentFlagValue | parseInt(entry[0])
                          );
                        } else {
                          setCurrentFlagValue(
                            currentFlagValue & ~parseInt(entry[0])
                          );
                        }
                      }}
                    />
                  </td>
                )}
                <td>
                  <label
                    htmlFor={`flag_${entry[0]}`}
                    className="cursor-pointer"
                  >
                    {entry[1]}
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            {flagType && (
              <tr>
                <th className="text-right pr-2">
                  <input
                    type="text"
                    className="w-full text-right outline-none appearance-none border-b border-b-shark-400"
                    style={{ backgroundColor: "transparent" }}
                    value={currentFlagValue !== 0 ? currentFlagValue : ""}
                    onChange={(e) => {
                      let value = parseInt(e.target.value);
                      if (isNaN(value) && value) {
                        toast.error("Not a number");
                        return;
                      }
                      if (!value) {
                        value = 0;
                      }
                      setCurrentFlagValue(value);
                    }}
                  />
                </th>
              </tr>
            )}
          </tfoot>
        </table>
      )}
    </div>
  );
}
